import mainStore from '@/store'
// eslint-disable-next-line import/extensions
import serviceModule from '@/store/main/warehouse'
import { FLAT_PICKER } from '@/views/components/DynamicForm/constants'

export default function config() {
  //   Constants
  const FULFILLMENT_ORDERS = 'Fulfillment Orders'
  const MODULE_NAME = 'warehouse-orders'

  const store = mainStore
  const model = serviceModule

  const warehouseWeeklyOrderFields = {
    selectDayOfWeek: {
      type: FLAT_PICKER,
      placeholder: 'Select a Date',
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },

  }
  const filterTabs = [
    {
      title: 'Week',
      query: 'week',
      tabNumber: 2,
      pageTitle: 'Fulfillment Orders',
    },
    {
      title: 'List',
      query: 'list',
      tabNumber: 3,
      pageTitle: 'Fulfillment Orders List',
    },
  ]
  const filterTabsList = [
    {
      title: 'By Orders',
      query: 'by-orders',
      tabNumber: 2,
      pageTitle: 'Fulfillment List By Orders',
    },
    {
      title: 'By Products',
      query: 'by-products',
      tabNumber: 3,
      pageTitle: 'Fulfillment List by Products',
    },
  ]

  const statusFilterTab = [
    {
      icon: 'LPickIcon',
      query: 'pick',
    },
    {
      icon: 'LBoxIcon',
      query: 'in-box',
    },
    {
      icon: 'LTruckIcon',
      query: 'on-truck',
    },
    {
      icon: 'LInOutIcon',
      query: 'on-in-out',
    },
    {
      icon: 'LWarehouseIcon',
      query: 'warehouse',
    },
    {
      icon: 'LUnPuckIcon',
      query: 'in-un-puck',
    },
    {
      icon: 'LBoxSentIcon',
      query: 'sent',
    },
    {
      icon: 'LDangerIcon',
      query: 'danger',
    },
  ]
  const ORDERS_STATUS_NO_ACTION = Object.freeze({
    id: 'no_action',
    status: 0,
    title: 'To be done',
    style: {
      color: '#D9D9D9',
      styleName: 'ORDERS_STATUS_NO_ACTION',
    },
  })
  const ORDERS_STATUS_IN_PROGRESS = Object.freeze({
    id: 'in_progress',
    status: 1,
    title: 'In progress',
    style: {
      color: '#4E1476',
      styleName: 'ORDERS_STATUS_IN_PROGRESS',
    },
  })
  const ORDERS_STATUS_ATTENTION = Object.freeze({
    id: 'attention',
    status: 2,
    title: 'Attention!',
    style: {
      color: '#BB2124',
      styleName: 'ORDERS_STATUS_ATTENTION',
    },
  })
  const ORDERS_STATUS_WARNING = Object.freeze({
    id: 'warning',
    status: 3,
    title: 'Warning',
    style: {
      color: '#F0AD4E',
      styleName: 'ORDERS_STATUS_WARNING',
    },
  })
  const ORDERS_STATUS_READY = Object.freeze({
    id: 'ready',
    status: 4,
    title: 'Ready',
    style: {
      color: '#00B139',
      styleName: 'ORDERS_STATUS_READY',
    },
  })
  const ORDERS_STATUS_VOID = Object.freeze({
    id: 'void',
    status: 5,
    title: 'Void',
    style: {
      color: '#000000',
      styleName: 'ORDERS_STATUS_VOID',
    },
  })

  const orderStatesAndStatuses = [
    { ...ORDERS_STATUS_NO_ACTION },
    { ...ORDERS_STATUS_IN_PROGRESS },
    { ...ORDERS_STATUS_ATTENTION },
    { ...ORDERS_STATUS_WARNING },
    { ...ORDERS_STATUS_READY },
    { ...ORDERS_STATUS_VOID },
  ]

  function checkItemStateAndStatus(item, index, stateAndStatuses) {
    if (!item) {
      return 'ORDERS_STATUS_NO_ACTION'
    }
    // eslint-disable-next-line no-nested-ternary,no-undef
    return item.state === index
      ? `${stateAndStatuses.find(({ status }) => status === item.status).style.styleName}`
      : index < item.state ? 'ORDERS_STATUS_READY' : 'ORDERS_STATUS_NO_ACTION'
  }
  return {
    FULFILLMENT_ORDERS,
    MODULE_NAME,
    store,
    model,
    filterTabs,
    filterTabsList,
    statusFilterTab,
    orderStatesAndStatuses,
    checkItemStateAndStatus,
    warehouseWeeklyOrderFields,
  }
}
